import {Component} from 'react';
import {Button, NavBar, Empty, Cell, Calendar, Divider, Flex, Loading} from 'react-vant';
import { CSVLink } from "react-csv";
import querystring from 'querystring';
import Footer from './footer';
import './base.css';
import './ticketTable.css';
import requests from './requests';


export default class TicketTable extends Component {

  constructor() {
    super();
    this.state = {
      requesting: false,
      visible: false,
      startDate: '',
      endDate: '',
      items: [],
      headers: [
        { label: "票类", key: "TKT_PRICE_TYPE" },
        { label: "影片", key: "FMT_FILM_TITLE" },
        { label: "票号", key: "IUR_TICKET_NO" },
        { label: "订单号", key: "TKT_BOOKING_ID" },
        { label: "购买时间", key: "IUR_RCRE_DATE" },
        { label: "放映日期", key: "IUR_BUSINESS_DATE" },
        { label: "放映时间", key: "TKT_SHOW_TIME" },
        { label: "影厅编号", key: "IUR_SCREEN_CODE" },
        { label: "补贴金额", key: "CINEMA_PAY" },
        { label: "支付金额", key: "BPM_AMT_PAID" },
        { label: "票价", key: "IUR_PRICE" },
        { label: "支付方式", key: "BPM_PAYMENT_TYPE" },
        { label: "排", key: "IUR_ROW_NUM" },
        { label: "列", key: "IUR_COL_NUM" },
        { label: "操作员", key: "IUR_RCRE_USER_ID" }
      ]
    }
  }

  get_date(timestamp) {
    let date = new Date(timestamp)
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
  }

  getDate(date, isFull=true) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (isFull) {
      return [year, month, day].join('-');
    } else {
      return [month, day].join('-');
    }
  }

  getData(startDate, endDate) {
    let url = "api/v1/findTicketDetailTable";
    this.setState({
      requesting: true,
      items: []
    })
    let data = querystring.stringify({startDate: startDate, endDate: endDate, type: "intercept"});
    requests.post(data, url).then((res) => {
      if(res.data.base_resp.ret === 0) {
        let items = res.data.data;
        let tickets = [];
        for (let i=0; i<items.length; i++) {
          for (let j=i+1; j<items.length; j++) {
            if (items[i].IUR_TICKET_NO === items[j].IUR_TICKET_NO) {
              tickets.push(items[i].IUR_TICKET_NO);
            }
          }
        }
        let result = [];
        let ticketsPays = {};
        for (let i=0; i<items.length; i++) {
          if (tickets.indexOf(items[i].IUR_TICKET_NO) !== -1) {
            if (items[i].BPM_PAYMENT_TYPE === "CinemaPay") {
              ticketsPays[items[i].IUR_TICKET_NO] = items[i].BPM_AMT_PAID;
            } else {
              result.push(items[i]);
            }
          } else {
            result.push(items[i]);
          }
        }
        for (let i=0; i<result.length; i++) {
          if (tickets.indexOf(result[i].IUR_TICKET_NO) !== -1) {
            result[i].CINEMA_PAY = ticketsPays[result[i].IUR_TICKET_NO];
          } else {
            result[i].CINEMA_PAY = 0;
          }
        }
        this.setState({
          requesting: false,
          items: result
        })
      }
    });
  }

  handleConfirm(items) {
    this.getData(this.getDate(items[0]), this.getDate(items[1]));
    this.setState({
      visible: false,
      startDate: this.getDate(items[0], true),
      endDate: this.getDate(items[1], true)
    })
  }

  handleClick() {
    this.setState({
      visible: !this.state.visible
    })
  }

  handleExport() {

  }

  get_data() {
    let itemsLength = this.state.items.length;
    if ( itemsLength < 1 && !this.state.requesting) {
      return (
          <Empty description="暂无数据" />
      )
    }

    let price = 0;
    for(let i = 0; i < itemsLength; i++) {
      let item = this.state.items[i];
      item.IUR_BUSINESS_DATE = this.get_date(item.IUR_BUSINESS_DATE);
      item.IUR_RCRE_DATE = this.get_date(item.IUR_RCRE_DATE);
      if (item.PYT_ITEM_DESC) {
        item.BPM_PAYMENT_TYPE = item.PYT_ITEM_DESC;
      }
      price += item.IUR_PRICE;
    }

    if (itemsLength < 1) {
      return null;
    } else {
      return (
        <div className="items">
          <Flex className="item" justify="center" align="center">
            <Flex.Item span={12}>{itemsLength}</Flex.Item>
            <Flex.Item span={12}>{price}</Flex.Item>
          </Flex>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="ticketTable">
        <NavBar title="数据导出" leftArrow={false} border={false}/>
        <div className="date">
          <Cell.Group border={false}>
            <Cell title="选择日期" value={this.state.startDate && this.state.startDate + " > " + this.state.endDate} isLink={true} onClick={this.handleClick.bind(this)} />
            <Calendar
              minDate={new Date(2020, 12, 1)}
              maxDate={new Date(2030, 12, 1)}
              defaultValue={new Date()}
              style={{ height: 500 }}
              visible={this.state.visible}
              allowSameDay={true}
              type={"range"}
              poppable={true}
              onClose={this.handleClick.bind(this)}
              onConfirm={(dates) => {
                this.handleConfirm(dates);
              }}
            />
          </Cell.Group>

        </div>
        <div className="content">
          <Divider />
          <div className="tips">
            注: 显示拦截数据
          </div>
          <Flex className="title">
            <Flex.Item span={12}>票数</Flex.Item>
            <Flex.Item span={12}>金额</Flex.Item>
          </Flex>
          {this.state.requesting &&
          <Loading>加载中...</Loading>
          }
          {this.get_data()}
        </div>
        {this.state.items.length !== 0 &&
          <div className="export">
            <CSVLink data={this.state.items} headers={this.state.headers} filename={this.state.startDate + ".csv"}>
              <Button type="primary" round={true} block={true}>导出明细</Button>
            </CSVLink>
          </div>
        }
        <Footer pathname="ticketTable" />
      </div>
    );
  }
}
