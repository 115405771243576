import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './home';
import Login from './login';
import Control from './control';
import Recover from './recover';
import RecoverSingle from './recoverSingle';
import RecoverToday from './recoverToday';
import Clear from './clear';
import Setting from './setting';
import Table from './table';
import Member from './member';
import ChangePassword from './changePassword';
import TicketTable from './ticketTable';
import { Router, Route, hashHistory } from "react-router";

ReactDOM.render(
  (<Router history={hashHistory}>
    <Route path="/" component={Home}/>
    <Route path="/login" component={Login} />
    <Route path="/control" component={Control} />
    <Route path="/recover" component={Recover} />
    <Route path="/changePassword" component={ChangePassword} />
    <Route path="/clear" component={Clear} />
    <Route path="/setting" component={Setting} />
    <Route path="/table" component={Table} />
    <Route path="/member" component={Member} />
    <Route path="/ticketTable" component={TicketTable} />
    <Route path="/recoverSingle" component={RecoverSingle} />
    <Route path="/recoverToday" component={RecoverToday} />
  </Router>),
  document.getElementById('root')
);
