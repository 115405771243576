import {Component} from 'react';
import { Form, Field, Button, NavBar, Cell, Tabs, Calendar, Dialog, Popup, Picker, Checkbox, CheckboxGroup, DatetimePicker, Toast, Grid} from 'react-vant';
import { LabelO } from '@react-vant/icons';
import querystring from 'querystring';
import { hashHistory } from "react-router";
import Footer from './footer';
import './base.css';
import './recover.css';
import requests from './requests';


export default class Recover extends Component {

  constructor() {
    super();
    this.state = {
      visible: false,
      dates: [],
      types: [],
      filmsData: [],
      films: [],
      type: '',
      filmCode: '',
      film: '',
      startTime: '',
      endTime: '',
      hallsData: [],
      halls: [],
      moviesData: [],
      movies: [],
      id: '',
      hall: '',
      code: '',
      movie: '',
      ticket: '',
      showTickets: false,
      tickets: [],
      tempTickets: [],
      upload: "N"
    }
  }


  componentDidMount() {
    let data = querystring.stringify();
    requests.post(data, "api/v1/findTicketTypeByNow").then((res) => {
      if(res.data.base_resp.ret === 0) {
        this.setState({
          types: res.data.data
        })
      }
    });
    requests.post(data, "api/v1/findFilmByNow").then((res) => {
      if(res.data.base_resp.ret === 0) {
        let films = res.data.data;
        let data = [];
        films.forEach(function (item) {
          data.push(item["FILMNAME"]);
        });
        this.setState({
          filmsData: films,
          films: data
        })
      }
    });
    requests.post(data, "api/v1/findHall").then((res) => {
      if(res.data.base_resp.ret === 0) {
        let halls = res.data.data;
        let data = [];
        halls.forEach(function (item) {
          data.push(item["CIN_REMARKS"]);
        });
        this.setState({
          hallsData: halls,
          halls: data,
        })
      }
    });
  }

  handleSubmit() {
    let dates = this.state.dates;
    let ticket = this.state.ticket;
    let upload = this.state.upload;
    if (dates.length > 0 || ticket) {
      Dialog.confirm({
        message:
          '将要恢复选择的数据，数据会上传，无法撤销，请慎重使用，确定恢复吗？',
      }).then(() => {
        let data;
        if (ticket) {
          data = querystring.stringify({dates: "", hallId: "", sessionCode: "", ticketCode: ticket, upload: upload});
        } else {
          let hallId =this.state.id;
          let sessionCode = this.state.code;
          data = querystring.stringify({dates: dates.join(','), hallId: hallId, sessionCode: sessionCode, ticketCode: ticket, upload: upload});
        }
        requests.post(data, "api/v1/recoverSingleData").then((res) => {
          if(res.data.base_resp.ret === 0) {
            Toast.success('恢复完成');
          }
        });
      }).catch(() => {
      });
    }
  }

  getDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  handleConfirmMulti(dates) {
    let _this = this;
    let data = [];
    dates.forEach(function (date) {
      data.push(_this.getDate(date));
    });
    this.setState({
      visible: false,
      dates: data,
      id: '',
      hall: '',
      code: '',
      movie: ''
    })
  }

  handleClick() {
    this.setState({
      visible: !this.state.visible
    })
  }

  handleTabChange(tab) {
  }

  handleChooseClick(item) {
    if (item === "showTypes") {
      if (this.state.types.length > 0) {
        this.setState({
          [item]: true
        });
      } else {
        Toast.info('当天没有选择票类');
      }
    } else if (item === "showFilms") {
      if (this.state.films.length > 0) {
        this.setState({
          [item]: true
        });
      } else {
        Toast.info('当天没有选择影片');
      }
    } else if (item === "showHalls") {
      if (this.state.halls.length > 0) {
        if (this.state.dates.length === 1) {
          this.setState({
            [item]: true
          });
        } else if (this.state.dates.length > 0) {
          Toast.info('只能选择一个日期');
        } else {
          Toast.info('选择一个日期');
        }
      } else {
        Toast.info('没有选择的影厅');
      }
    } else if (item === "showMovies") {
      if (this.state.movies.length > 0) {
        if (this.state.dates.length === 1) {
          if (this.state.hall) {
            this.setState({
              [item]: true
            });
          } else {
            Toast.info('选择一个影厅');
          }
        } else if (this.state.dates.length > 0) {
          Toast.info('只能选择一个日期');
        } else {
          Toast.info('选择一个日期');
        }
      } else {
        Toast.info('没有选择的场次');
      }
    } else {
      this.setState({
        [item]: true
      });
    }
  }

  handleSearch() {
    if (this.state.startTime && this.state.endTime) {
      let data = querystring.stringify({type: this.state.type, film: this.state.filmCode, startTime: this.state.startTime, endTime: this.state.endTime});
      requests.post(data, "api/v1/findOrderInfoByDetail").then((res) => {
        if(res.data.base_resp.ret === 0) {
          if (res.data.data.length > 0) {
            this.setState({
              showTickets: true,
              tickets: res.data.data
            })
          } else {
            Toast.info('没有数据');
          }
        }
      });
    } else {
      Toast.info('请选择时间');
    }
  }

  handleTypeCancle() {
    this.setState({
      showTypes: false
    });
  }

  handleTypeConfirm(value) {
    this.setState({
      type: value,
      showTypes: false
    });
  }

  handleFilmCancle() {
    this.setState({
      showFilms: false
    });
  }

  handleFilmConfirm(value) {
    let id = '';
    this.state.filmsData.forEach(function (item) {
      if (item["FILMNAME"] === value) {
        id = item["FILMCODE"];
      }
    });
    this.setState({
      filmCode: id,
      film: value,
      showFilms: false
    });
  }

  handleStartTimeCancle() {
    this.setState({
      showStartTime: false
    });
  }

  handleStartTimeConfirm(value) {
    let date = new Date();
    this.setState({
      startTime: this.getDate(date) + " " + value + ":00",
      showStartTime: false
    });
  }

  handleEndTimeCancle() {
    this.setState({
      showEndTime: false
    });
  }

  handleEndTimeConfirm(value) {
    let date = new Date();
    this.setState({
      endTime: this.getDate(date) + " " + value + ":00",
      showEndTime: false
    });
  }

  handleHallCancle() {
    this.setState({
      showHalls: false
    });
  }

  handleHallConfirm(value) {
    let id = '';
    this.state.hallsData.forEach(function (item) {
      if (item["CIN_REMARKS"] === value) {
        id = item["CIN_CINEMA_CD"];
      }
    });
    this.setState({
      id: id,
      hall: value,
      code: '',
      movie: '',
      showHalls: false
    });
    let data = querystring.stringify({id: id, date: this.state.dates[0]});
    requests.post(data, "api/v1/findPlanByHall").then((res) => {
      if(res.data.base_resp.ret === 0) {
        let movies = res.data.data;
        let data = [];
        movies.forEach(function (item) {
          data.push(item["FILMNAME"] + item["SHOWSTARTTIME"].split(" ")[1]);
        });
        this.setState({
          moviesData: movies,
          movies: data
        })
      }
    });
  }

  handleMovieCancle() {
    this.setState({
      showMovies: false
    });
  }

  handleMovieConfirm(value) {
    let code = '';
    this.state.moviesData.forEach(function (item) {
      if (item["FILMNAME"] + item["SHOWSTARTTIME"].split(" ")[1] === value) {
        code = item["SESSIONCODE"];
      }
    });
    this.setState({
      code: code,
      movie: value,
      showMovies: false
    });
  }

  handleTicketChange(value) {
    this.setState({
      ticket: value
    })
  }

  handleGroupChange(names) {
    this.setState({
      tempTickets: names
    })
  }

  handleTicketsSubmit() {
    let tempTickets = this.state.tempTickets;
    if (tempTickets.length > 0) {
      let data = querystring.stringify({orders: tempTickets.join(',')});
      requests.post(data, "api/v1/recoverSetTicket").then((res) => {
        if(res.data.base_resp.ret === 0) {
          Toast.success('恢复完成');
        }
      });
    }
    this.setState({
      showTickets: false,
      tempTypes: []
    });
  }

  handleRecoverToday() {
    Dialog.confirm({
      message:
        '将要恢复今天的数据，数据会上传，无法撤销，请慎重使用，确定恢复吗？',
    }).then(() => {
      let data = querystring.stringify({});
      requests.post(data, "api/v1/recoverTodayTickets").then((res) => {
        if(res.data.base_resp.ret === 0) {
          Toast.success('恢复完成');
        }
      });
    }).catch(() => {
    });
  }

  handleTicketsClose() {
    this.setState({
      showTickets: false,
      tempTypes: []
    });
  }

  handleUrlClick(url) {
    hashHistory.push(url);
  }

  get_ticket_data() {
    let _this = this;
    let items = this.state.tickets.map(function(item, index) {
      return (
        <Cell key={index}>
          <Checkbox name={item.ORDERCODE}>{item.TICKETTYPENAME}, {item.FILMNAME}, {item.PLANTIME}, {item.TICKETCODES}</Checkbox>
        </Cell>
      )
    });
    if (items.length > 0) {
      return (
        <div className="tickets">
          <CheckboxGroup onChange={_this.handleGroupChange.bind(_this)}>
            <Cell.Group border={false}>
              {items}
            </Cell.Group>
            <div className="types">
              <Button className="button first" type="default" onClick={_this.handleTicketsClose.bind(_this)} >
                取消
              </Button>
              <Button className="button" type="primary" onClick={_this.handleTicketsSubmit.bind(_this)} >
                恢复
              </Button>
            </div>
          </CheckboxGroup>
        </div>
      )
    } else {
      return;
    }
  }

  handleUploadChange(names) {
    let upload = "N";
    if (names.length > 0) {
      upload = "Y";
    }
    this.setState({
      upload: upload
    })
  }

  render() {
    return (
      <div className="recover">
        <NavBar title="恢复" leftArrow={false} border={false}/>
        <div className={this.state.showTickets ? "hide" : "active"}>
          <Tabs active={0} onChange={this.handleTabChange.bind(this)}>
            <Tabs.TabPane key={0} title="日结前">
              <div className="tabs">
                <div className="before">
                  <Form>
                    <Cell.Group border={false}>
                      <Field className="input" label="选择票类" placeholder="请选择" value={this.state.type} onClick={this.handleChooseClick.bind(this, "showTypes")} isLink={true} readonly={true} />
                      <Field className="input" label="选择影片" placeholder="请选择" value={this.state.film} onClick={this.handleChooseClick.bind(this, "showFilms")} isLink={true} readonly={true} />
                      <Field className="input" label="选择开始时间" placeholder="请选择" value={this.state.startTime} onClick={this.handleChooseClick.bind(this, "showStartTime")} isLink={true} readonly={true} />
                      <Field className="input" label="选择结束时间" placeholder="请选择" value={this.state.endTime} onClick={this.handleChooseClick.bind(this, "showEndTime")} isLink={true} readonly={true} />
                    </Cell.Group>
                  </Form>
                </div>
                <div>
                  <Button className="button" type="primary" round={true} block={true} onClick={this.handleSearch.bind(this)} >
                    查询
                  </Button>
                </div>
                <div className="track">
                  <Grid columnNum={2} border={false} gutter={10}>
                    <Grid.Item className="track-item-2" icon={<LabelO />} onClick={this.handleRecoverToday.bind(this)} text="一键恢复" />
                  </Grid>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane key={1} title="日结后">
              <div className="tabs">
                <div className="before">
                  <Form>
                    <Cell.Group border={false}>
                      <Cell title="选择日期" value={this.state.dates.length > 0 && "选择了" + this.state.dates.length + "个日期"} isLink={true} onClick={this.handleClick.bind(this)} />
                      <Cell title="选择影厅" value={this.state.hall} isLink={true} onClick={this.handleChooseClick.bind(this, "showHalls")} />
                      <Cell title="选择场次" value={this.state.movie} isLink={true} onClick={this.handleChooseClick.bind(this, "showMovies")} />
                      <Field className="input" label="单票恢复" placeholder="请输入票号" onChange={this.handleTicketChange.bind(this)} value={this.state.ticket} />

                      <Calendar
                        minDate={new Date(2020, 12, 1)}
                        maxDate={new Date(2030, 12, 1)}
                        defaultValue={new Date()}
                        style={{ height: 500 }}
                        visible={this.state.visible}
                        type={"multiple"}
                        poppable={true}
                        onClose={this.handleClick.bind(this)}
                        onConfirm={(dates) => {
                          this.handleConfirmMulti(dates);
                        }}
                      />
                    </Cell.Group>
                  </Form>
                </div>
                <Checkbox.Group className="upload" onChange={this.handleUploadChange.bind(this)}>
                  <Cell.Group>
                    <Cell
                      title='恢复不上报'
                      rightIcon={<Checkbox name="Y" />}
                    />
                  </Cell.Group>
                </Checkbox.Group>
                <div>
                  <Button className="button" type="primary" round={true} block={true} onClick={this.handleSubmit.bind(this)} >
                    确定
                  </Button>
                </div>
                <div className="track">
                  <Grid columnNum={2} border={false} gutter={10}>
                    <Grid.Item className="track-item-1" icon={<LabelO />} onClick={this.handleUrlClick.bind(this, "/recoverSingle")} text="查询票号恢复" />
                  </Grid>
                </div>
              </div>
            </Tabs.TabPane>
            </Tabs>
          <Footer index={2}/>
          <Popup visible={this.state.showTypes} position="bottom" round={true} title="选择票类">
            <Picker columns={this.state.types} onCancel={this.handleTypeCancle.bind(this)} onConfirm={this.handleTypeConfirm.bind(this)}>
            </Picker>
          </Popup>
          <Popup visible={this.state.showFilms} position="bottom" round={true} title="选择影片">
            <Picker columns={this.state.films} onCancel={this.handleFilmCancle.bind(this)} onConfirm={this.handleFilmConfirm.bind(this)}>
            </Picker>
          </Popup>
          <Popup visible={this.state.showStartTime} position="bottom" round={true} title="选择开始时间">
            <DatetimePicker type="time" onCancel={this.handleStartTimeCancle.bind(this)} onConfirm={this.handleStartTimeConfirm.bind(this)}>
            </DatetimePicker>
          </Popup>
          <Popup visible={this.state.showEndTime} position="bottom" round={true} title="选择结束时间">
            <DatetimePicker type="time" onCancel={this.handleEndTimeCancle.bind(this)} onConfirm={this.handleEndTimeConfirm.bind(this)}>
            </DatetimePicker>
          </Popup>
          <Popup visible={this.state.showHalls} position="bottom" round={true} title="选择影厅">
            <Picker columns={this.state.halls} onCancel={this.handleHallCancle.bind(this)} onConfirm={this.handleHallConfirm.bind(this)}>
            </Picker>
          </Popup>
          <Popup visible={this.state.showMovies} position="bottom" round={true} title="选择场次">
            <Picker columns={this.state.movies} onCancel={this.handleMovieCancle.bind(this)} onConfirm={this.handleMovieConfirm.bind(this)}>
            </Picker>
          </Popup>
        </div>
        <div className={this.state.showTickets ? "active" : "hide"}>
          {this.get_ticket_data()}
        </div>
      </div>
    );
  }
}
