import {Component} from 'react';
import { Form, Field, Button, NavBar, Cell, Popup, Checkbox, CheckboxGroup, DatetimePicker, Toast} from 'react-vant';
import querystring from 'querystring';
import Footer from './footer';
import './base.css';
import './recover.css';
import requests from './requests';


export default class RecoverToday extends Component {

  constructor() {
    super();
    this.state = {
      visible: false,
      startTime: '',
      endTime: '',
      showTickets: false,
      tickets: [],
      tempTickets: [],
    }
  }


  componentDidMount() {
  }

  getDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  handleChooseClick(item) {
    this.setState({
      [item]: true
    });
  }

  handleSearch() {
    if (this.state.startTime && this.state.endTime) {
      let data = querystring.stringify({startTime: this.state.startTime, endTime: this.state.endTime});
      requests.post(data, "api/v1/today/orders").then((res) => {
        if(res.data.base_resp.ret === 0) {
          if (res.data.data.length > 0) {
            this.setState({
              showTickets: true,
              tickets: res.data.data
            })
          } else {
            Toast.info('没有数据');
          }
        }
      });
    } else {
      Toast.info('请选择时间');
    }
  }

  handleStartTimeCancle() {
    this.setState({
      showStartTime: false
    });
  }

  handleStartTimeConfirm(value) {
    let date = new Date();
    this.setState({
      startTime: this.getDate(date) + " " + value + ":00",
      showStartTime: false
    });
  }

  handleEndTimeCancle() {
    this.setState({
      showEndTime: false
    });
  }

  handleEndTimeConfirm(value) {
    let date = new Date();
    this.setState({
      endTime: this.getDate(date) + " " + value + ":00",
      showEndTime: false
    });
  }

  handleGroupChange(names) {
    this.setState({
      tempTickets: names
    })
  }

  handleTicketsSubmit() {
    let tempTickets = this.state.tempTickets;
    if (tempTickets.length > 0) {
      let data = querystring.stringify({orders: tempTickets.join(',')});
      requests.post(data, "api/v1/recoverSetTicket").then((res) => {
        if(res.data.base_resp.ret === 0) {
          Toast.success('恢复完成');
        }
      });
    }
    this.setState({
      showTickets: false,
      tempTypes: []
    });
  }

  handleTicketsClose() {
    this.setState({
      showTickets: false,
      tempTypes: []
    });
  }

  get_ticket_data() {
    let _this = this;
    let items = this.state.tickets.map(function(item, index) {
      return (
        <Cell key={index}>
          <Checkbox name={item.ORDERCODE}>{item.TICKETTYPENAME}, {item.FILMNAME}, {item.PLANTIME}, {item.TICKETCODES}</Checkbox>
        </Cell>
      )
    });
    if (items.length > 0) {
      return (
        <div className="tickets">
          <CheckboxGroup onChange={_this.handleGroupChange.bind(_this)}>
            <Cell.Group border={false}>
              {items}
            </Cell.Group>
            <div className="types">
              <Button className="button first" type="default" onClick={_this.handleTicketsClose.bind(_this)} >
                取消
              </Button>
              <Button className="button" type="primary" onClick={_this.handleTicketsSubmit.bind(_this)} >
                恢复
              </Button>
            </div>
          </CheckboxGroup>
        </div>
      )
    } else {
      return;
    }
  }

  render() {
    return (
      <div className="recover">
        <NavBar title="日结前恢复" leftArrow={false} border={false}/>
        <div className={this.state.showTickets ? "hide" : "active"}>
              <div className="tabs">
                <div className="before">
                  <Form>
                    <Cell.Group border={false}>
                      <Field className="input" label="选择开始时间" placeholder="请选择" value={this.state.startTime} onClick={this.handleChooseClick.bind(this, "showStartTime")} isLink={true} readonly={true} />
                      <Field className="input" label="选择结束时间" placeholder="请选择" value={this.state.endTime} onClick={this.handleChooseClick.bind(this, "showEndTime")} isLink={true} readonly={true} />
                    </Cell.Group>
                  </Form>
                </div>
                <div>
                  <Button className="button" type="primary" round={true} block={true} onClick={this.handleSearch.bind(this)} >
                    查询
                  </Button>
                </div>
              </div>
          <Footer index={2}/>
          <Popup visible={this.state.showStartTime} position="bottom" round={true} title="选择开始时间">
            <DatetimePicker type="time" onCancel={this.handleStartTimeCancle.bind(this)} onConfirm={this.handleStartTimeConfirm.bind(this)}>
            </DatetimePicker>
          </Popup>
          <Popup visible={this.state.showEndTime} position="bottom" round={true} title="选择结束时间">
            <DatetimePicker type="time" onCancel={this.handleEndTimeCancle.bind(this)} onConfirm={this.handleEndTimeConfirm.bind(this)}>
            </DatetimePicker>
          </Popup>
        </div>
        <div className={this.state.showTickets ? "active" : "hide"}>
          {this.get_ticket_data()}
        </div>
      </div>
    );
  }
}
